import { useState, useEffect } from 'react';
import { ChartContainer, ScatterChart} from '@mui/x-charts';
import { Chip } from '@mui/material';


const FftChart = ({ctx}) => {

  if (!ctx.data || ctx.data.length === 0){
    ctx = {data: [{data:[
      [
          1732803450912,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"8.81\"},{\"frequency\":\"8.33\",\"magnitude\":\"5.92\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.43\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.06\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.62\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.56\"},{\"frequency\":\"8.28\",\"magnitude\":\"0.96\"},{\"frequency\":\"8.39\",\"magnitude\":\"0.91\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.79\"},{\"frequency\":\"8.20\",\"magnitude\":\"0.62\"}]",
          [
              1732803450912,
              1732803450912
          ]
      ],
      [
          1732803512438,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"8.41\"},{\"frequency\":\"8.33\",\"magnitude\":\"5.63\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.55\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.07\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.33\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.16\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.10\"},{\"frequency\":\"8.28\",\"magnitude\":\"0.91\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.78\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.72\"}]",
          [
              1732803512438,
              1732803512438
          ]
      ],
      [
          1732803574085,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"8.67\"},{\"frequency\":\"8.33\",\"magnitude\":\"5.56\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.21\"},{\"frequency\":\"8.31\",\"magnitude\":\"1.99\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.47\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.24\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.93\"},{\"frequency\":\"8.28\",\"magnitude\":\"0.79\"},{\"frequency\":\"8.39\",\"magnitude\":\"0.76\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.72\"}]",
          [
              1732803574085,
              1732803574085
          ]
      ],
      [
          1732803635669,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"8.94\"},{\"frequency\":\"8.33\",\"magnitude\":\"5.60\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.67\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.08\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.50\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.16\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.12\"},{\"frequency\":\"8.28\",\"magnitude\":\"0.75\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.75\"},{\"frequency\":\"8.43\",\"magnitude\":\"0.74\"}]",
          [
              1732803635669,
              1732803635669
          ]
      ],
      [
          1732803697283,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"8.89\"},{\"frequency\":\"8.33\",\"magnitude\":\"5.62\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.47\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.16\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.50\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.49\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.14\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.13\"},{\"frequency\":\"8.41\",\"magnitude\":\"1.09\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.95\"}]",
          [
              1732803697283,
              1732803697283
          ]
      ],
      [
          1732803758899,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"8.84\"},{\"frequency\":\"8.33\",\"magnitude\":\"5.63\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.52\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.38\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.67\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.39\"},{\"frequency\":\"8.39\",\"magnitude\":\"0.89\"},{\"frequency\":\"8.28\",\"magnitude\":\"0.89\"},{\"frequency\":\"8.43\",\"magnitude\":\"0.83\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.80\"}]",
          [
              1732803758899,
              1732803758899
          ]
      ],
      [
          1732803820486,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.37\"},{\"frequency\":\"8.33\",\"magnitude\":\"5.91\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.50\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.26\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.44\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.34\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.19\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.09\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.91\"},{\"frequency\":\"8.43\",\"magnitude\":\"0.88\"}]",
          [
              1732803820486,
              1732803820486
          ]
      ],
      [
          1732803882002,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.39\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.22\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.64\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.23\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.71\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.43\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.24\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.22\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.85\"},{\"frequency\":\"8.43\",\"magnitude\":\"0.85\"}]",
          [
              1732803882002,
              1732803882002
          ]
      ],
      [
          1732803943607,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.73\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.30\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.72\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.45\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.59\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.48\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.16\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.14\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.97\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.90\"}]",
          [
              1732803943607,
              1732803943607
          ]
      ],
      [
          1732804005180,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.89\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.28\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.88\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.58\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.59\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.32\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.18\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.06\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.96\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.82\"}]",
          [
              1732804005180,
              1732804005180
          ]
      ],
      [
          1732804066756,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.91\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.16\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.77\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.42\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.62\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.53\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.33\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.08\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.91\"},{\"frequency\":\"8.43\",\"magnitude\":\"0.80\"}]",
          [
              1732804066756,
              1732804066756
          ]
      ],
      [
          1732804128362,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.37\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.52\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.82\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.34\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.69\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.57\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.37\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.08\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.90\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.83\"}]",
          [
              1732804128362,
              1732804128362
          ]
      ],
      [
          1732804190017,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.26\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.61\"},{\"frequency\":\"8.36\",\"magnitude\":\"3.00\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.69\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.61\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.27\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.21\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.21\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.89\"},{\"frequency\":\"8.25\",\"magnitude\":\"0.85\"}]",
          [
              1732804190017,
              1732804190017
          ]
      ],
      [
          1732804251563,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.24\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.64\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.94\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.50\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.71\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.65\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.21\"},{\"frequency\":\"8.41\",\"magnitude\":\"1.10\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.10\"},{\"frequency\":\"8.20\",\"magnitude\":\"0.90\"}]",
          [
              1732804251563,
              1732804251563
          ]
      ],
      [
          1732804313201,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.43\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.67\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.93\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.52\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.75\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.50\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.23\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.17\"},{\"frequency\":\"8.41\",\"magnitude\":\"1.01\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.85\"}]",
          [
              1732804313201,
              1732804313201
          ]
      ],
      [
          1732804374817,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.46\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.19\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.94\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.69\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.76\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.61\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.09\"},{\"frequency\":\"8.39\",\"magnitude\":\"0.98\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.93\"},{\"frequency\":\"8.20\",\"magnitude\":\"0.83\"}]",
          [
              1732804374817,
              1732804374817
          ]
      ],
      [
          1732804436367,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.67\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.64\"},{\"frequency\":\"8.36\",\"magnitude\":\"3.02\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.69\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.71\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.59\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.21\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.04\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.94\"},{\"frequency\":\"8.43\",\"magnitude\":\"0.82\"}]",
          [
              1732804436367,
              1732804436367
          ]
      ],
      [
          1732804498061,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.61\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.72\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.89\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.48\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.82\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.76\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.29\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.28\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.96\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.82\"}]",
          [
              1732804498061,
              1732804498061
          ]
      ],
      [
          1732804559678,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.98\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.98\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.86\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.45\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.56\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.39\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.13\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.98\"},{\"frequency\":\"8.28\",\"magnitude\":\"0.98\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.85\"}]",
          [
              1732804559678,
              1732804559678
          ]
      ],
      [
          1732804621231,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.90\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.91\"},{\"frequency\":\"8.36\",\"magnitude\":\"3.00\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.40\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.72\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.65\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.24\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.22\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.89\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.89\"}]",
          [
              1732804621231,
              1732804621231
          ]
      ],
      [
          1732804682801,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.75\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.60\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.90\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.83\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.71\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.34\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.31\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.10\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.93\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.92\"}]",
          [
              1732804682801,
              1732804682801
          ]
      ],
      [
          1732804744396,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.35\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.50\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.92\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.61\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.88\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.83\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.17\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.11\"},{\"frequency\":\"8.41\",\"magnitude\":\"1.04\"},{\"frequency\":\"8.26\",\"magnitude\":\"1.01\"}]",
          [
              1732804744396,
              1732804744396
          ]
      ],
      [
          1732804806015,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.30\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.34\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.80\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.55\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.64\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.55\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.02\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.01\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.99\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.83\"}]",
          [
              1732804806015,
              1732804806015
          ]
      ],
      [
          1732804867628,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.39\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.47\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.91\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.41\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.79\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.70\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.16\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.15\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.91\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.89\"}]",
          [
              1732804867628,
              1732804867628
          ]
      ],
      [
          1732804929243,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"10.18\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.59\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.79\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.70\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.71\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.54\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.24\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.09\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.85\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.83\"}]",
          [
              1732804929243,
              1732804929243
          ]
      ],
      [
          1732804990808,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.97\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.45\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.65\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.05\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.63\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.61\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.20\"},{\"frequency\":\"8.28\",\"magnitude\":\"0.96\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.91\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.89\"}]",
          [
              1732804990808,
              1732804990808
          ]
      ],
      [
          1732805052512,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.72\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.05\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.92\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.53\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.67\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.46\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.12\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.04\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.94\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.88\"}]",
          [
              1732805052512,
              1732805052512
          ]
      ],
      [
          1732805114118,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.44\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.10\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.79\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.59\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.76\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.65\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.22\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.07\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.88\"},{\"frequency\":\"8.25\",\"magnitude\":\"0.86\"}]",
          [
              1732805114118,
              1732805114118
          ]
      ],
      [
          1732805175728,
          "[{\"frequency\":\"8.34\",\"magnitude\":\"9.24\"},{\"frequency\":\"8.33\",\"magnitude\":\"6.07\"},{\"frequency\":\"8.36\",\"magnitude\":\"2.79\"},{\"frequency\":\"8.31\",\"magnitude\":\"2.29\"},{\"frequency\":\"8.38\",\"magnitude\":\"1.58\"},{\"frequency\":\"8.30\",\"magnitude\":\"1.18\"},{\"frequency\":\"8.39\",\"magnitude\":\"1.08\"},{\"frequency\":\"8.28\",\"magnitude\":\"1.04\"},{\"frequency\":\"8.41\",\"magnitude\":\"0.92\"},{\"frequency\":\"8.26\",\"magnitude\":\"0.81\"}]",
          [
              1732805175728,
              1732805175728
          ]
      ]
    ]}]}
  }

  console.log("ctx", ctx);

  const tbData = ctx.data[0].data.map(dataPoint => {return {time: dataPoint[0], data: JSON.parse(dataPoint[1])}});

  const [series, setSeries] = useState(tbData.map((tbDataPoint) => (
    {
      label: new Date(tbDataPoint.time).toLocaleTimeString(),
      data: tbDataPoint.data.map((point) => ({x: point.frequency, y: point.magnitude, id: `${point.frequency}-${tbDataPoint.time}`}))
    }
  )
  ))

  console.log("series", series);
  
  const renderWaveform = () => {
    return (
      <div style={{width: "100%", height: "100%", minHeight: "600px", minWidth: "800px"}}>
        <ScatterChart
        style={{width: "100%", height: "100%", minHeight: "600px", minWidth: "800px"}}
        series={series}
        grid={{ vertical: true, horizontal: true }}
        xAxis={[{ scaleType: 'linear', label: 'Frequency (Hz)' }]}
        yAxis={[{ scaleType: 'linear', label: 'Magnitude (dBm)' } ]}
        />
      </div>

    )
  };

  if (!series) return <div>No data</div>;

  return (
    <div className="">
      <div className='flex justify-center'>
        {renderWaveform()}
      </div>
      <div className="flex justify-around items-center">

      </div>
    </div>
  );
};

export default FftChart;